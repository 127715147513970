import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { PosService } from '../../services/pos';
import { Store, select } from "@ngrx/store";
import { Observable } from 'rxjs';

import { ModalController } from '@ionic/angular';
import * as fromRoot from "../../../state/app.reducer";

@Component({
  selector: 'app-store',
  templateUrl: './store.page.html',
  styleUrls: ['./store.page.scss'],
})
export class ModalStorePage implements OnInit {
  stores:any=[];
  pos:any=[];
  id_store:number;
  id_pos:number;
  
  constructor(
    private  router:  Router,
    private storage: Storage,
    private modalController: ModalController,
    private  posService:  PosService,
    private store: Store<fromRoot.AppState>
  ) { }

  ngOnInit() {
   this.loadStores()
  }
  async loadStores(){
    let store = await this.posService.getStores()
    store.subscribe(stores =>{
      this.stores = stores.data;
    });
  }
  
  async loadPOSs(){
    let pos = await this.posService.getPOSs()
    pos.subscribe(pos =>{
      console.log({pos:pos.data});
      this.pos = pos.data;
    });
  }
  
  async changeStore(evt){
    this.pos=[];
    await this.storage.set('id_store',this.id_store);
    this.loadPOSs();
  }
  async save(){
    console.log({id_store:this.id_store,id_pos:this.id_pos});
    if(this.id_store>=0 && this.id_pos>=0){
      await this.storage.set('id_store',this.id_store);
      await this.storage.set('id_pos',this.id_pos);
      this.modalController.dismiss({result:"hide"});
    }

  }
}

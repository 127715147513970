import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot,CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(
    public auth: AuthenticationService,
    private  router:  Router,
    private storage: Storage
  ) {}
 
  async canActivate() {

    let val = await this.storage.get('current_user');
    
    if(val && val.company && val.token){
      return true;
    }else{
      this.router.navigate(['login']);
      return false;
    }
    //return this.auth.isAuthenticated();
  }
}

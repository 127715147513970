import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { EmployeeService } from '../../services/employee';
import { CartService } from '../../services/cart';
import { PosService } from '../../services/pos';

import { ModalOpenDayPage } from '../open-day/open-day.page';
import { ModalCloseDayPage } from '../close-day/close-day.page';
import { ModalDocumentsPage } from '../documents/documents.page';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-options',
  templateUrl: './options.page.html',
  styleUrls: ['./options.page.scss'],
})
export class ModalOptionsPage implements OnInit {
  options:any =[];
  dayOpened:boolean;
  constructor(
    private  router:  Router,
    private storage: Storage,
    private  employeeService:  EmployeeService,
    private modalController: ModalController,
    private  cartService:  CartService,
    private  posService:  PosService,

  ) { }

  ngOnInit() {
    this.checkOpenDay();
  }
  
  async showOpenDayModal(){
    const modal = await this.modalController.create({
      component: ModalOpenDayPage,
      cssClass:"fullscreen-modal"

    });  
    modal.present();
    const { data } = await modal.onDidDismiss();
    this.modalController.dismiss();
  }  

  async showDocumentsModal(){
    const modal = await this.modalController.create({
      component: ModalDocumentsPage,
      cssClass:"fullscreen-modal"

    });  
    modal.present();
    const { data } = await modal.onDidDismiss();
    this.modalController.dismiss();
  }  
  async showCloseDayModal(){
    const modal = await this.modalController.create({
      component: ModalCloseDayPage,
      cssClass:"fullscreen-modal"

    });  
    modal.present();
    const { data } = await modal.onDidDismiss();
    this.modalController.dismiss();
  }  
  

  checkOpenDay(){
    this.posService.checkOpenDay().subscribe( data =>{
      console.log({dt:data.data});
      if(data.data.length>0)  this.dayOpened = true;
      else this.dayOpened = false;
    })
    
  }

  back(){
    this.modalController.dismiss();
  }

  

}

import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { PosService } from '../../services/pos';
import { Store, select } from "@ngrx/store";
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';

import { ModalController } from '@ionic/angular';
import * as fromRoot from "../../../state/app.reducer";

@Component({
  selector: 'app-open-day',
  templateUrl: './open-day.page.html',
  styleUrls: ['./open-day.page.scss'],
})
export class ModalOpenDayPage implements OnInit {
  options:any =[];
  configurations$: Observable<any>;
  currencyNotes:any = [];
  currencyCoins:any = [];
  total:number = 0;
  constructor(
    private  router:  Router,
    private storage: Storage,

    private modalController: ModalController,
    private  posService:  PosService,
    private store: Store<fromRoot.AppState>,
    public toastController: ToastController,


  ) { }

  ngOnInit() {
    this.configurations$ = this.store.pipe(select(fromRoot.getConfigurations));
    this.configurations$.subscribe(data=>{
      for(let d of data.currencies_detail){
        if(d.type=="N"){
          d.currentValue=0;
          this.currencyNotes.push(d);
        }
        if(d.type=="C"){
          d.currentValue=0;
          this.currencyCoins.push(d);
        }
      }
    });
  }
  
  getTotals(){
    this.total=0;
    for(let d of this.currencyNotes){
      this.total += Number(d.currentValue)*Number(d.value);
    }
    for(let d of this.currencyCoins){
      this.total += Number(d.currentValue)*Number(d.value);
    }
  }
  back(){
    this.modalController.dismiss();
  }
  async save(){
    let params ={
      total:this.total
    };
    this.posService.saveOpenDay(params).subscribe(async d =>  {
      let data = d.data;
        
      const toast = await this.toastController.create({
        message: data.message,
        duration: 2000
      });
      toast.present();
      this.modalController.dismiss();
    });
  }

  
  

}

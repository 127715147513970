import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { PosService } from '../../services/pos';
import { Observable } from 'rxjs';
import { Store, select } from "@ngrx/store";
import * as fromRoot from "../../../state/app.reducer";
import { ModalPaymentMechanismPage } from '../payment-mechanism/payment-mechanism.page';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-invoice-customer',
  templateUrl: './invoice-customer.page.html',
  styleUrls: ['./invoice-customer.page.scss'],
})
export class ModalInvoiceCustomerPage implements OnInit {
  configurations$: Observable<any>;
  employees:any =[];
  invoiceCustomer:any = {
    billing_id_country:15,
    billing_vat_number:"",
    billing_name:"",
    billing_address1:"",
    billing_postcode:"",
    billing_city:""
  }
  
  
  constructor(
    private  router:  Router,
    private storage: Storage,
    private posService: PosService,
    private store: Store<fromRoot.AppState>,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.configurations$ = this.store.pipe(select(fromRoot.getConfigurations));
  }
  async save(){
    await this.posService.saveInvoiceCustomer(this.invoiceCustomer);
    this.showPaymentMechanismModal();
  }
  async showPaymentMechanismModal(){
    const modal = await this.modalController.create({
      component: ModalPaymentMechanismPage,
//      cssClass:"fullscreen-modal"

    });  
    modal.present();
    const { data } = await modal.onDidDismiss();
    this.modalController.dismiss();
  }  

  back(){
    this.modalController.dismiss();
  }
  compareCountry = (o1, o2) => {
    console.log({o1,o2});
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };
}


import { Injectable } from '@angular/core';
import { SailsClient} from "ngx-sails";
import { Storage } from '@ionic/storage';

@Injectable()
export class ConfigurationsService {
  requestOpt:{};
  constructor(
    private  sails: SailsClient,
    private storage: Storage, 
  ) { 
     
    this.storage.get('current_user').then(val =>{
      if(val && val.company && val.token){
        let requestOpt = { 
          headers: {
              Authorization: 'Bearer '+val.token,
              host: val.company
          },
        };
        console.log({requestOpt});
        this.requestOpt = requestOpt;
      }  
    });
  }
  
  getConfigurations(){
    return this.sails.get("/pos/v1/configurations",this.requestOpt);
  }
  getCurrencies(){
    return this.sails.get("/pos/v1/currencies/details",this.requestOpt);
  }
  openDrawer(){
    return this.sails.get("/pos/v1/openDrawer",this.requestOpt);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { DocumentsService } from '../../services/documents';
import { Store, select } from "@ngrx/store";
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';

import { ModalController } from '@ionic/angular';
import * as fromRoot from "../../../state/app.reducer";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.page.html',
  styleUrls: ['./documents.page.scss'],
})
export class ModalDocumentsPage implements OnInit {
  docs:any = [];

  constructor(
    private  router:  Router,
    private storage: Storage,
    private modalController: ModalController,
    private  documentsService:  DocumentsService,
    private store: Store<fromRoot.AppState>,
    public toastController: ToastController,


  ) { }

  ngOnInit() {
    this.getDocuments();
  }
  
  async getDocuments(){
    let docs = await this.documentsService.getDocuments({});
    docs.subscribe(data => {
      this.docs= data.data;
    });
  
  }
  refresh(){
    this.getDocuments();
  }
  
  async printDocument(id){
    let pd= await this.documentsService.printDocument({id});
    pd.subscribe(print=>console.log({print}))
  }
  

  
  

}

import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../services/category';
import { CartService } from '../services/cart';
import { Store } from "@ngrx/store";

import * as fromRoot from "../../state/app.reducer";
import * as cart from "../../state/cart/cart.actions";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.page.html',
  styleUrls: ['./categories.page.scss'],
})
export class CategoriesPage implements OnInit {
  categories = [];
  constructor(
    private  categoryService:  CategoryService,
    private  cartService:  CartService,
    private store: Store<fromRoot.AppState>

  ) { }

  async ngOnInit() {
    let data = await this.categoryService.getCategories();
    console.log({data});
    data.subscribe(data => {
      this.categories = data.data
      console.log({categories:this.categories});
    });

    console.log("getCategories",this.categories);

  }
  async addProductToCard(c){
    console.log({c});
    this.store.dispatch(new cart.AddToCart(c));
  }

}

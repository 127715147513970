import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

 
const TOKEN_KEY = 'auth-token';
const USER = 'user';
 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  authenticationState = new BehaviorSubject(false);
 
  constructor(private storage: Storage, private plt: Platform,public http: HttpClient) { 
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }
 
  login(data) {
    console.log({data})
   let headers = new HttpHeaders({
     'Access-Control-Allow-Origin' : '*',
     'Access-Control-Allow-Methods' : 'POST, GET, OPTIONS, PUT',
     'Accept' : 'application/json',
     'Content-Type': 'application/json'
   });
 let options = {
    headers: headers
 }


    this.storage.get(USER).then(res => {
      if (res) {
        return this.http.post<any>("https://"+res+".womni.store/back/v1/auth/login",data,options);
      }
    })


    return this.storage.set(TOKEN_KEY, 'Bearer 1234567').then(() => {
      this.authenticationState.next(true);
    });
  }
 
  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
    });
  }
  recovery(data){
    return this.http.post("/back/v1/auth/recovery",data);
  }
  validateToken(data){
    return this.http.post("/back/v1/auth/validate",data);
  }
  resetPassword(data){
    return this.http.post("/back/v1/auth/reset",data);
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
  

}
import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { EmployeeService } from '../../services/employee';
import { CartService } from '../../services/cart';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.page.html',
  styleUrls: ['./employee.page.scss'],
})
export class ModalEmployeePage implements OnInit {
  employees:any =[];
  constructor(
    private  router:  Router,
    private storage: Storage,
    private  employeeService:  EmployeeService,
    private modalController: ModalController,
    private  cartService:  CartService,

  ) { }

  ngOnInit() {
    this.cartService.getCart();

    this.getEmployees();    
    console.log("ModalEmployeePage");
  }
  
  
  async getEmployees(){
     let data = await this.employeeService.getEmployees();
     data.subscribe(data => this.employees = data.data);
     console.log("getEmployees");
  }
  async selectEmployee(e){
    await this.employeeService.setLocalEmployee(e);
    this.modalController.dismiss(e);
  }
  
}

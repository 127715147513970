import { Component, OnInit } from '@angular/core';
import { ModalEmployeePage } from '../modals/employee/employee.page';
import { ModalStorePage } from '../modals/store/store.page';
import { Storage } from '@ionic/storage';

import { ModalController } from '@ionic/angular';
import { Store, select } from "@ngrx/store";
import { Observable } from 'rxjs';

import * as fromRoot from "../../state/app.reducer";
import * as cart from "../../state/cart/cart.actions";
import * as configurations from "../../state/configurations/configurations.actions";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.html',
  styleUrls: ['./layout.scss'],
})
export class LayoutComponent implements OnInit {
  configurations$: Observable<any>;

  constructor(
      public modalController: ModalController,
      private store: Store<fromRoot.AppState>,
      private storage: Storage, 
    ) { }

  ngOnInit() {
    this.store.dispatch(new cart.GetCart());
    this.store.dispatch(new configurations.GetConfigurations());
    
    this.configurations$ = this.store.pipe(select(fromRoot.getConfigurations));
    this.showEmployeeModal();
    this.showStoreModal();
  }
  async showEmployeeModal(){
    const modal = await this.modalController.create({
      component: ModalEmployeePage,
      cssClass:"fullscreen-modal"
    });  
    modal.present();
    const { data } = await modal.onDidDismiss();
  }  

  async showStoreModal(){
    let id_store = await this.storage.get('id_store');
    let id_pos =  await this.storage.get('id_pos');
    console.log({id_store,id_pos});
    if(id_store=null || id_pos==null || !(id_store>=0) || !(id_pos>=0)){
      console.log("entrou");
      const modal = await this.modalController.create({
        component: ModalStorePage,
        cssClass:"fullscreen-modal"
      });  
      modal.present();
      const { data } = await modal.onDidDismiss();
    }
  }
  


}

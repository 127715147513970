import {CartActions,CartActionTypes} from './cart.actions';

export interface State {
    cart:any,
    loading: boolean;
    error: string;
    totals:any;
}

export const initialState: State = {
    cart: [],
    loading: false,
    error: '',
    totals:{
        grossTotal:0,
        netTotal:0,
        taxTotal:0,
        
    }
    
};
export function updateCart(state,action){
    state.totals.grossTotal=0;
    if(!state.cart) state.cart=[];
    for (let item of state.cart) {
        console.log({item});
        state.totals.grossTotal+=item.price;
    }
    return state.totals;
}

export function reducer(state = initialState, action: CartActions): State {
    switch (action.type) {

        case CartActionTypes.GetCart: {

            return {
                ...state,
                loading: true
            };
        }

        case CartActionTypes.GetCartSuccess: {
            console.log({cart:action.payload});
            state.cart = action.payload;
            state.totals = updateCart(state,action);

            return {
                ...state,
                loading: false,
                cart:action.payload
            };
        }

        case CartActionTypes.GetCartError: {
            return {
                ...state,
                loading: false,
                error: 'error loading todos'
            };
        }
        
         case CartActionTypes.AddToCart: {
            console.log("ADD CART",action.payload,state.cart);
            if(!state.cart) state.cart = [];
            
            state.cart.push(action.payload);
            state.totals = updateCart(state,action);
            return {
                ...state,
                loading: true
            };
        }

        case CartActionTypes.AddToCartSuccess: {
            console.log("ADD_CART_SUCCESS");
            state.totals = updateCart(state,action);
            return {
                ...state,
                loading: false,
                cart:action.payload
            };
        }

        case CartActionTypes.AddToCartError: {
            console.log("ADD_CART_FAIL");
            state.totals = updateCart(state,action);

            return {
                ...state,
                loading: false,
                error: 'error loading todos'
            };
        }
         case CartActionTypes.CleanCart: {
console.log("CleanCart");

            return {
                ...state,
                loading: true
            };
        }

        case CartActionTypes.CleanCartSuccess: {
            console.log("CleanCartSuccess");
            state.cart = [];
            state.totals = updateCart(state,action);

            return {
                ...state,
                loading: false,
                cart:[]
            };
        }

        case CartActionTypes.CleanCartError: {
            console.log("CleanCartError");
            state.totals = updateCart(state,action);
            return {
                ...state,
                loading: false,
                error: 'error cleaning todos'
            };
        }


        default: {
            return state;
        }
        
    }
    
}

export const getCart = (state: State) => state.cart;

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getPos = (state: State) => state.error;
export const getTotals = (state: State) => state.totals;

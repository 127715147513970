
import { Injectable } from '@angular/core';
import { SailsClient} from "ngx-sails";
import { Storage } from '@ionic/storage';

@Injectable()
export class DocumentsService {
  requestOpt:{};

  constructor(
    private  sails: SailsClient,
    private storage: Storage, 
  ) { 
    
  }  
  async getDocuments(params) {
    let store = await this.storage.get('id_store');
    let pos = await this.storage.get('id_pos');
    let employee = await this.storage.get('employee');
    let val = await this.storage.get('current_user');
    if(val && val.company && val.token){
      let requestOpt = { 
        headers: {
            Authorization: 'Bearer '+val.token,
            host: val.company,
            "x-womni-employee": employee ? employee.id : null,
            "x-womni-store": store,
            "x-womni-pos": pos
        },
        params
      };
      return this.sails.get("/pos/v1/documents",requestOpt);
    }  

  }
  
  async printDocument(params) {
   let store = await this.storage.get('id_store');
    let pos = await this.storage.get('id_pos');
    let employee = await this.storage.get('employee');
    let val = await this.storage.get('current_user');
    if(val && val.company && val.token){
      let requestOpt = { 
        headers: {
            Authorization: 'Bearer '+val.token,
            host: val.company,
            "x-womni-employee": employee ? employee.id : null,
            "x-womni-store": store,
            "x-womni-pos": pos
        },
        params
      };
      return this.sails.get("/pos/v1/print/document",requestOpt);

    }
  }
  


}

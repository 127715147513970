import { ActionReducerMap, MetaReducer, createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromCart from './cart/cart.reducer';
import * as fromConfigurations from './configurations/configurations.reducer';


export interface AppState {
    cart: fromCart.State;
    configurations: fromConfigurations.State;
}
export const appReducer: ActionReducerMap<AppState> = {
    cart: fromCart.reducer,
    configurations: fromConfigurations.reducer
};


export const getCartState = createFeatureSelector<fromCart.State>('cart');

export const getConfigurationsState = createFeatureSelector<fromConfigurations.State>('configurations');

export const getCart = createSelector(
    getCartState,
    fromCart.getCart
  );
export const getTotals = createSelector(
    getCartState,
    fromCart.getTotals
);

export const getConfigurations = createSelector(
    getConfigurationsState,
    fromConfigurations.getConfigurations
  );

import {ConfigurationsActions,ConfigurationsActionTypes} from './configurations.actions';

export interface State {
    configurations:any,
    loading: boolean;
    error: string;
}

export const initialState: State = {
    configurations:[],
    loading: false,
    error: ''
};

export function reducer(state = initialState, action: ConfigurationsActions): State {
    switch (action.type) {
         case ConfigurationsActionTypes.ConfigurationsRequest: {
            return {
                ...state,
                loading: true
            };
        }

        case ConfigurationsActionTypes.ConfigurationsRequestSuccess: {
            console.log({pl:action.payload.data});
            return {
                ...state,
                loading: false,
                configurations:action.payload.data
            };
        }

        case ConfigurationsActionTypes.ConfigurationsRequestError: {
            return {
                ...state,
                loading: false,
                error: 'error loading todos'
            };
        }


        default: {
            return state;
        }
    }
}

export const getConfigurations = (state: State) => state.configurations;

export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
export const getPos = (state: State) => state.error;

import { Action } from "@ngrx/store";

export enum ConfigurationsActionTypes {
  ConfigurationsRequest = '[Configurations] Configurations request',
  ConfigurationsRequestSuccess = '[Configurations] Configurations request success',
  ConfigurationsRequestError = '[Configurations]  Configurations request error',
}

export class GetConfigurations implements Action {
  readonly type = ConfigurationsActionTypes.ConfigurationsRequest;
}
export class ConfigurationsRequestSuccess implements Action {
  readonly type = ConfigurationsActionTypes.ConfigurationsRequestSuccess;
  constructor(public payload: any = null) {}
}

export class ConfigurationsRequestError implements Action {
  readonly type = ConfigurationsActionTypes.ConfigurationsRequestError;
  constructor(public payload: any = null) {}
}



export type ConfigurationsActions =
    GetConfigurations
    | ConfigurationsRequestSuccess
    | ConfigurationsRequestError
    ;
import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { PosService } from '../../services/pos';
import { Store, select } from "@ngrx/store";
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';

import { ModalController } from '@ionic/angular';
import * as fromRoot from "../../../state/app.reducer";

@Component({
  selector: 'app-close-day',
  templateUrl: './close-day.page.html',
  styleUrls: ['./close-day.page.scss'],
})
export class ModalCloseDayPage implements OnInit {
  totals:any = [];

  constructor(
    private  router:  Router,
    private storage: Storage,
    private modalController: ModalController,
    private  posService:  PosService,
    private store: Store<fromRoot.AppState>,
    public toastController: ToastController,


  ) { }

  ngOnInit() {
    this.posService.getDayTotals().subscribe(data =>{
      let total = 0;
      for(let p of data.data.payments ){
        total+=p.amount;
      }
      data.data.total = total;
      this.totals = data.data
      console.log({totals:this.totals});
    } );
    
  }
  
  printTotals(){
    
  }
  
  closeDay(){
    console.log("closeDay");
    this.posService.closeDay().subscribe(async d =>  {
      
      let data = d.data;
      const toast = await this.toastController.create({
        message: data.message,
        duration: 2000
      });
      toast.present();
      this.modalController.dismiss();
    });

    
  }
  printPosTotals(){
    this.posService.printPosTotals().subscribe(async d =>  {
      let data = d.data;
      const toast = await this.toastController.create({
        message: data.message,
        duration: 2000
      });
      toast.present();
      this.modalController.dismiss();

    });
  }

  
  

}

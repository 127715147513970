
import { Injectable } from '@angular/core';
import { SailsClient} from "ngx-sails";
import { Storage } from '@ionic/storage';

@Injectable()
export class CartService {
  requestOpt:{};

  constructor(
    private  sails: SailsClient,
    private storage: Storage, 
  ) { 
    this.storage.get('id_store').then(store =>{
      this.storage.get('id_pos').then(pos =>{
        this.storage.get('employee').then(employee =>{
          console.log({employee,store,pos});
          this.storage.get('current_user').then(val =>{
              if(val && val.company && val.token){
                let requestOpt = { 
                  headers: {
                      Authorization: 'Bearer '+val.token,
                      host: val.company,
                      "x-womni-employee": employee ? employee.id : null,
                      "x-womni-store": store,
                      "x-womni-pos": pos
                  },
                };
                this.requestOpt = requestOpt;
              }  
            });
          });
        });
      });
  }
    roundNumber(rnum, rlength) { 
    	if((rnum>=0.00045)&&(rnum<0.01)){
    	    return (Math.round(rnum*Math.pow(10,3))/Math.pow(10,3)).toFixed(3);
        }else if((rnum>=0.000045)&&(rnum<=0.001)){
    		return (Math.round(rnum*Math.pow(10,4))/Math.pow(10,4)).toFixed(4);
    	}if((rnum>=0.0000045)&&(rnum<=0.0001)){
    		return (Math.round(rnum*Math.pow(10,5))/Math.pow(10,5)).toFixed(5);
    	}else{
            return (Math.round(rnum*Math.pow(10,rlength))/Math.pow(10,rlength)).toFixed(rlength);
    	}
  
    }

  async addToCart(prod) {
    console.log("addToCart",{prod});
    let lineTax=1;
    let price_default="price";
    let basePrice=0;
    let decimal = 2;
    let count=0;
    let nr_rows=0;
    
    let data = prod;
    var tax = 1;	
    
    if(lineTax==1) tax = 1+(Number(data.rate)/100);
    
		basePrice= Number(this.roundNumber(data[price_default]/(1+(Number(data.rate)/100)),decimal));
	console.log({basePrice});
	var myNewItem = {
	  count:count,
		line: nr_rows-1,
		id_product: data.id,
		id_product_attribute: data.id_product_attribute,
		ean13: data.ean13,
		reference: data.reference,
		supplier_reference: data.supplier_reference,
		upc: data.upc,
		name: data.langs[0].name,
		tax_exemption: data.tax_exemption,
		originatingOn: "",
		originatingDate:"",
		referenceInvoice:"",
		reason:"",
		price: data.price,
		unity: data.unity,
		id_uom:data.id_uom,
		uom:data.uom,
		wholesale_price: data.wholesale_price,
		pmc: data.pmc,
		wholesale_lastprice: data.wholesale_lastprice,
		id_tax_rules_group: data.id_tax_rules_group ,
		tax: data.rate,
		prodType:data.prodType,
		base_price: basePrice,
		qt: 1,
		desc1:0,
		desc2:0,
		stotal: 0
	};

// DISCOUNT AUTO					
/*  			 <% if(conf['POS_DISCOUNT_AUTO'] && conf['POS_DISCOUNT_AUTO']==1){ %>
		if(data.promotion && data.promotion.length>0){
		  if(data.promotion[0].reduction_type=="percentage"){
		    myNewItem.desc1= data.promotion[0].reduction*100;
		  }
		}
		<% } %>
*/
//          count++;
//          nr_rows++;				

    let val = await this.storage.get('current_cart');
    if(val && val.length>0){
      val.push(myNewItem);
    }else{
      val = [];
      val.push(myNewItem);
    }
    await this.storage.set('current_cart',val);
    return await this.storage.get('current_cart');
  }
  
  async getCart() {
    
    return await this.storage.get('current_cart');
  }
  
  async cleanCart() {
    return await this.storage.remove('current_cart');
  }
  async saveDocument(payments){
    let lines = await this.storage.get('current_cart');
    let invoiceCustomer = await this.storage.get('invoice-customer');
    let emp = await this.storage.get('employee');
    this.requestOpt["headers"]["x-womni-employee"]= emp.id
    console.log({requestOpt:this.requestOpt});
    return this.sails.post("/pos/v1/document",{lines,payments,invoiceCustomer},this.requestOpt);
  }
  

/*
  async getCart() {
    let val = await this.storage.get('current_user');
    if(val && val.company && val.token){
      let requestOpt = { 
        headers: {
            Authorization: 'Bearer '+val.token,
            host: val.company
        },
      };
      return this.sails.get("/pos/v1/category/1/products",requestOpt);
    }
  }
*/


}

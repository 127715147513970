import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { LayoutComponent } from './pos/layout/layout';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule} from "@angular/common/http";
import { EmployeeService }  from "./pos/services/employee";
import { CategoryService }  from "./pos/services/category";
import { CustomerService }  from "./pos/services/customer";

import {FormsModule} from '@angular/forms';

import { CartService }  from "./pos/services/cart";
import { PosService }  from "./pos/services/pos";
import { DocumentsService }  from "./pos/services/documents";

import { ConfigurationsService }  from "./pos/services/configurations";
import { StateModule } from './state/state.module';

import { CategoriesPage }  from "./pos/categories/categories.page";
import { ModalEmployeePage } from './pos/modals/employee/employee.page';
import { ModalOptionsPage } from './pos/modals/options/options.page';
import { ModalOpenDayPage } from './pos/modals/open-day/open-day.page';
import { ModalCloseDayPage } from './pos/modals/close-day/close-day.page';
import { ModalDocumentsPage } from './pos/modals/documents/documents.page';

import { ModalPaymentMechanismPage } from './pos/modals/payment-mechanism/payment-mechanism.page';

import { ModalStorePage } from './pos/modals/store/store.page';

import { ModalInvoiceCustomerPage } from './pos/modals/invoice-customer/invoice-customer.page';

import {ISailsClientConfig, SailsClientModule} from 'ngx-sails';
const socketConfig: ISailsClientConfig = { 
  uri: 'https://api-dev.womni.store',

};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    CategoriesPage,
    ModalEmployeePage,
    ModalOptionsPage,
    ModalOpenDayPage,
    ModalPaymentMechanismPage,
    ModalCloseDayPage,
    ModalStorePage,
    ModalDocumentsPage,
    ModalInvoiceCustomerPage,
    ],
  entryComponents: [
    ModalEmployeePage,
    ModalOptionsPage,
    ModalOpenDayPage,
    ModalCloseDayPage,
    ModalDocumentsPage,
    ModalStorePage,
    ModalPaymentMechanismPage,
    ModalInvoiceCustomerPage
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    FormsModule,
    SailsClientModule.configureClient(socketConfig),
    StateModule.forRoot(),
  ],
  providers: [
    EmployeeService,
    DocumentsService,
    CategoryService,
    CustomerService,
    CartService,
    PosService,
    ConfigurationsService,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

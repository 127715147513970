
import { Action } from "@ngrx/store";

export enum CartActionTypes {
  GetCart = '[Cart] Get Cart',
  GetCartSuccess = '[Cart] Get Cart Success',
  GetCartError = '[Cart] Get Cart Error',
  
  AddToCart = '[Cart] Add to Cart',
  AddToCartSuccess = '[Cart] Add to Cart Success',
  AddToCartError = '[Cart] Add to Cart Error',
  
  CleanCart = '[Cart] Clean Cart',
  CleanCartSuccess = '[Cart] Clean Cart Success',
  CleanCartError = '[Cart] Clean Cart Error',
  
  OpenDrawer = '[Cart] Open Drawer',
  OpenDrawerSuccess = '[Cart] Open Drawer Success',
  OpenDrawerError = '[Cart] Open Drawer Error',

}

export class GetCart implements Action {
  readonly type = CartActionTypes.GetCart;
}
export class GetCartSuccess implements Action {
  readonly type = CartActionTypes.GetCartSuccess;
  constructor(public payload: any = null) {}
}
export class GetCartError implements Action {
  readonly type = CartActionTypes.GetCartError;
  constructor(public payload: any = null) {}
}

export class AddToCart implements Action {
  readonly type = CartActionTypes.AddToCart;
  constructor(public payload: any = null) {}
}
export class AddToCartSuccess implements Action {
  readonly type = CartActionTypes.AddToCartSuccess;
  constructor(public payload: any = null) {}
}
export class AddToCartError implements Action {
  readonly type = CartActionTypes.AddToCartError;
  constructor(public payload: any = null) {}
}

export class CleanCart implements Action {
  readonly type = CartActionTypes.CleanCart;
}
export class CleanCartSuccess implements Action {
  readonly type = CartActionTypes.CleanCartSuccess;
  constructor(public payload: any = null) {}
}
export class CleanCartError implements Action {
  readonly type = CartActionTypes.CleanCartError;
  constructor(public payload: any = null) {}
}

export class OpenDrawer implements Action {
  readonly type = CartActionTypes.OpenDrawer;
}
export class OpenDrawerSuccess implements Action {
  readonly type = CartActionTypes.OpenDrawerSuccess;
  constructor(public payload: any = null) {}
}
export class OpenDrawerError implements Action {
  readonly type = CartActionTypes.OpenDrawerError;
  constructor(public payload: any = null) {}
}


export type CartActions =
    GetCart
    | GetCartSuccess
    | GetCartError
    | AddToCart
    | AddToCartSuccess
    | AddToCartError
    | CleanCart
    | CleanCartSuccess
    | CleanCartError
    | OpenDrawer
    | OpenDrawerSuccess
    | OpenDrawerError
    ;
import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Storage } from '@ionic/storage';
import { CartService } from '../../services/cart';
import { DocumentsService } from '../../services/documents';

import { Observable } from 'rxjs';
import { Store, select } from "@ngrx/store";
import * as fromRoot from "../../../state/app.reducer";
import * as cart from "../../../state/cart/cart.actions";

import { ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { ModalEmployeePage } from '../../modals/employee/employee.page';

@Component({
  selector: 'app-payment-mechanism',
  templateUrl: './payment-mechanism.page.html',
  styleUrls: ['./payment-mechanism.page.scss'],
})
export class ModalPaymentMechanismPage implements OnInit {
  configurations$: Observable<any>;
  totals:any = {};
  constructor(
    private cartService: CartService,
    private documentsService : DocumentsService,
    private modalController: ModalController,
    public toastController: ToastController,
    private store: Store<fromRoot.AppState>,


  ) { }

  ngOnInit() {
    this.store.pipe(select(fromRoot.getTotals)).subscribe(d => this.totals=d);
    console.log({totals:this.totals});
    
  }
  
  async saveDocument(payments){
    console.log(payments);
    let doc = await this.cartService.saveDocument(payments)
    doc.subscribe(async d =>  {
      let data = d.data;
      const toast = await this.toastController.create({
        message: data.message,
        duration: 2000
      });
      toast.present();
      this.store.dispatch(new cart.CleanCart());
      this.modalController.dismiss();
      //this.showEmployeeModal();
      await this.printDocument(d.data.data.id);
    });
  }
async printDocument(id){
    let pd= await this.documentsService.printDocument({id});
    pd.subscribe(print=>console.log({print}))
  }

  async showEmployeeModal(){
    const modal = await this.modalController.create({
      component: ModalEmployeePage,
    });  
    modal.present();
    const { data } = await modal.onDidDismiss();
  }  

  
}

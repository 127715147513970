
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of,  } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

import {
    CartActionTypes,
    GetCart,
    GetCartSuccess,
    GetCartError,
    AddToCart,
    AddToCartSuccess,
    AddToCartError,
    CleanCart,
    CleanCartSuccess,
    CleanCartError
} from './cart.actions';

import { CartService } from '../../pos/services/cart';

@Injectable()
export class CartEffects {
    
/*    @Effect()
    getCart$ = this.actions$.pipe(
        ofType(CartActions.GET_CART),
        tap(() => {
                            console.log("GET_CART");
//            new CartActions.GetCartSuccess(this.cartService.getCart())
        })
    );*/
    @Effect()
    loadCart$ = this.actions$.pipe(
        ofType<GetCart>(CartActionTypes.GetCart),
//        map(action => action.payload),
        switchMap(async (p) =>{
            let cart = await this.cartService.getCart();
            return new GetCartSuccess(cart);
        })
    );
    @Effect()
    addToCart$ = this.actions$.pipe(
        ofType<AddToCart>(CartActionTypes.AddToCart),
        map(action => action.payload),
        switchMap(async (p) =>{
            let cart = await this.cartService.addToCart(p);
          return new AddToCartSuccess(cart)
        })
    );
    @Effect()
    clenaCart$ = this.actions$.pipe(
        ofType<CleanCart>(CartActionTypes.CleanCart),
        switchMap(async (p) =>{
            let cart = await this.cartService.cleanCart();
            return new CleanCartSuccess()
        })
    );



    constructor(private actions$: Actions, private cartService: CartService) { }
}
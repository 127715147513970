import { NgModule } from '@angular/core';

import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './pos/layout/layout';
const routes: Routes = [
  { path:  'login', loadChildren:  './auth/login/login.module#LoginPageModule' },
 // { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { 
    path: '', 
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children:[
      { path: '', loadChildren: './pos/home/home.module#HomePageModule' },
      { path: 'home', loadChildren: './pos/home/home.module#HomePageModule' },
    ]
  },
  { path:  '',  redirectTo: '/login', pathMatch: 'full' },
  

];  

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}


import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of,  } from 'rxjs';
import { map, first, switchMap, catchError, tap, exhaustMap } from 'rxjs/operators';

import {
  ConfigurationsActionTypes,
  GetConfigurations,
  ConfigurationsRequestSuccess,
  ConfigurationsRequestError
} from './configurations.actions';
import { ConfigurationsService } from '../../pos/services/configurations';

@Injectable()
export class ConfigurationsEffects {
    
    @Effect()
    loadConfigurations$: Observable<any> = this.actions$.pipe(
        ofType<GetConfigurations>(ConfigurationsActionTypes.ConfigurationsRequest),
        switchMap( ():any => {
            return this.configurationsService.getConfigurations().pipe(
                map((c) =>new ConfigurationsRequestSuccess(c)),
                catchError(error => of(new ConfigurationsRequestError(error)))

            )

        })
    );


    constructor(private actions$: Actions, private configurationsService: ConfigurationsService) { }
}
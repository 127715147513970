
import { Injectable } from '@angular/core';
import { SailsClient} from "ngx-sails";
import { Storage } from '@ionic/storage';

@Injectable()
export class PosService {
  requestOpt:{};

  constructor(
    private  sails: SailsClient,
    private storage: Storage, 
  ) { 
    
    this.storage.get('id_store').then(store =>{
      this.storage.get('id_pos').then(pos =>{

        this.storage.get('employee').then(employee =>{
          console.log({employee,store,pos});
          this.storage.get('current_user').then(val =>{
              if(val && val.company && val.token){
                let requestOpt = { 
                  headers: {
                      Authorization: 'Bearer '+val.token,
                      host: val.company,
                      "x-womni-employee": employee ? employee.id : null,
                      "x-womni-store": store,
                      "x-womni-pos": pos
                  },
                };
                console.log({requestOpt});
                this.requestOpt = requestOpt;
              }  
            });
          });
        });
      });
  }
  saveOpenDay(data){
    return this.sails.put("/pos/v1/open-day",data,this.requestOpt);
  }
  printPosTotals(){
    return this.sails.get("/pos/v1/print/pos-totals",this.requestOpt);
  }
  closeDay(){
    return this.sails.put("/pos/v1/close-day",{},this.requestOpt);
  }
  checkOpenDay(){
    return this.sails.get("/pos/v1/check-open-day",this.requestOpt);
  }
  openDrawer(){
    return this.sails.put("/pos/v1/open-drawer",{},this.requestOpt);
  }
  getDayTotals(){
    return this.sails.get("/pos/v1/day-totals",this.requestOpt);
  }
  
  async getStores() {
    let val = await this.storage.get('current_user');

    if(val && val.company && val.token){
      let requestOpt = { 
        headers: {
            Authorization: 'Bearer '+val.token,
            host: val.company
        },
      };
      return this.sails.get("/pos/v1/stores",requestOpt);
    }
  }
  async getPOSs(){
    let id_store = await this.storage.get('id_store');
    let val = await this.storage.get('current_user');

    if(val && val.company && val.token){
      let requestOpt = { 
        headers: {
            Authorization: 'Bearer '+val.token,
            host: val.company
        },
      };
      return this.sails.get("/pos/v1/store/"+id_store+"/pos",this.requestOpt);
    }
  }
  async getStore() {
    return await this.storage.get('id_store');
  }
  async getPOS() {
    return await this.storage.get('id_pos');
  }
  async saveInvoiceCustomer(customer){
    await this.storage.set('invoice-customer',customer);
  }
  


}

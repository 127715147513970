
import { Injectable } from '@angular/core';
import { SailsClient} from "ngx-sails";
import { Storage } from '@ionic/storage';

@Injectable()
export class CategoryService {

  constructor(
    private  sails: SailsClient,
    private storage: Storage, 
  ) { }
  
  async getCategories() {
    let val = await this.storage.get('current_user');
    if(val && val.company && val.token){
      let requestOpt = { 
        headers: {
            Authorization: 'Bearer '+val.token,
            host: val.company
        },
      };
      return this.sails.get("/pos/v1/category/1/products",requestOpt);
    }
  }


}
